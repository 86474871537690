<template>
    <div>
        <div class="filter-container">
            <div class="filter-row">
                <div class="filter-block">
                    <b-form-group label="Область/район">
                        <b-form-select
                            v-model="region"
                            :options="listReg"
                            value-field="code"
                            @change="updateDatas"
                        ></b-form-select>
                    </b-form-group>
                </div>l;
                <div class="filter-block">
                    <b-form-group label="АБП" v-if="dictTree !== null">
                        <label class="typo__label">Tagging</label>
                        <multiselect v-model="selectedTree"
                                     :options="dictTree"
                                     :multiple="true"
                                     :close-on-select="false"
                                     :clear-on-select="false"
                                     :preserve-search="true"
                                     :preselect-first="true"
                                     placeholder="Выберите АБП"
                                     label="text"
                                     track-by="text">
                            <pre class="language-json"><code>{{ values  }}</code></pre>
<!--                            <template slot="selection" slot-scope="{ values, search, isOpen }">-->
<!--                                <span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">-->
<!--                                    выбрано: {{ values.length }} АБП-->
<!--                                </span>-->
<!--                            </template>-->
                        </multiselect>
                    </b-form-group>
                </div>
                <b-button class="filter-add"
                          variant="success"
                          v-show="selectedTree.length>0"
                          @click="addAbp">+
                </b-button>
            </div>
        </div>
        <b-progress variant="primary" v-show="bar<100" height="3px" :value="bar" striped animated></b-progress>
        <b-card class="table-container">
            <b-table
                :fields="tableFields"
                :items="budgetForm"
                :tbody-tr-class="rowClass"
                :filter="filter.search"
                :filter-included-fields="filter.on"
                responsive="true"
                bordered
                head-variant="light"
                sticky-header="true"
                no-border-collapse>
                <template #head(action)="scope">
                    <b-button @click="openAll()">
                        <i class="icon icon-keyboard icon-rotate-270" v-if="open"></i>
                        <i class="icon icon-keyboard icon-rotate-90" v-if="!open"></i>
                    </b-button>
                </template>
                <template #head(abp)="scope">
                    <div>АБП</div>
                    <br>
                    <b-form-input
                        id="filter-input"
                        v-model="filter.abp"
                        type="search"
                    ></b-form-input>
                </template>
                <template #head(prg)="scope">
                    <div>БП</div>
                    <br>
                    <b-form-input
                        id="filter-input"
                        v-model="filter.prg"
                        type="search"
                    ></b-form-input>
                </template>
                <template #head(ppr)="scope">
                    <div>БПП</div>
                    <br>
                    <b-form-input
                        id="filter-input"
                        v-model="filter.ppr"
                        type="search"
                    ></b-form-input>
                </template>
                <template #head(name_ru)="scope">
                    <div>Наименование</div>
                    <br>
                    <b-form-input
                        id="filter-input"
                        v-model="filter.name_ru"
                        type="search"
                    ></b-form-input>
                </template>

                <template #cell(action)="data">
                    <b-button v-if="data.item.type === 3" @click="openChilds(data.item)">
                        <i class="icon icon-keyboard icon-rotate-270" v-if="data.item.open"></i>
                        <i class="icon icon-keyboard icon-rotate-90" v-if="!data.item.open"></i>
                    </b-button>
                </template>
                <template #cell(abp)="data">
                    <div v-if="data.item.type === 3" class="text-right">{{ data.value }}</div>
                    <b-button v-if="(data.item.type === 4) && data.item.hasChild"
                              @click="openChilds(data.item)">
                        <i class="icon icon-keyboard icon-rotate-270" v-if="data.item.open"></i>
                        <i class="icon icon-keyboard icon-rotate-90" v-if="!data.item.open"></i>
                    </b-button>
                </template>
                <template #cell(prg)="data">
                    <div v-if="data.item.type === 4" class="text-right">{{ data.value }}</div>
                </template>
                <template #cell(ppr)="data">
                    <div v-if="data.item.type === 5" class="text-right">{{ data.value }}</div>
                </template>
                <template #cell(name_ru)="data">
                    <div>{{ data.value }}</div>
                </template>
                <template #cell()="data">
                    <div class="text-right">
                        {{ (isNaN(data.value) || (data.value === 0)) ? null : $n(data.value) }}
                    </div>
                </template>
                <template #cell(budget_request)="data">
                    <template v-if="(data.item.editable)">
                        <b-form-input class="text-right"
                                      :value="$n(data.item.budget_request)"
                                      @change="v => data.item.budget_request = v"
                                      @keyup.enter.exact="keyup13"
                                      @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                      @blur="inputFixed(data.item, 'budget_request', data.item.budget_request, 1)">
                        </b-form-input>
                    </template>
                    <template v-else>
                        <div class="text-right">
                            {{ (isNaN(data.value) || (data.value === 0)) ? null : $n(data.value) }}
                        </div>
                    </template>
                </template>
                <template #cell(plan1)="data">
                    <template v-if="(data.item.editable)">
                        <b-form-input class="text-right"
                                      :value="$n(data.item.plan1)"
                                      @change="v => data.item.plan1 = v"
                                      @keyup.enter.exact="keyup13"
                                      @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                      @blur="inputFixed(data.item, 'plan1', data.item.plan1, 1)">
                        </b-form-input>
                    </template>
                    <template v-else>
                        <div class="text-right">
                            {{ (isNaN(data.value) || (data.value === 0)) ? null : $n(data.value) }}
                        </div>
                    </template>
                </template>
                <template #cell(plan2)="data">
                    <template v-if="(data.item.editable)">
                        <b-form-input class="text-right"
                                      :value="$n(data.item.plan2)"
                                      @change="v => data.item.plan2 = v"
                                      @keyup.enter.exact="keyup13"
                                      @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                      @blur="inputFixed(data.item, 'plan2', data.item.plan2, 1)">
                        </b-form-input>
                    </template>
                    <template v-else>
                        <div class="text-right">
                            {{ (isNaN(data.value) || (data.value === 0)) ? null : $n(data.value) }}
                        </div>
                    </template>
                </template>
                <template #cell(plan3)="data">
                    <template v-if="(data.item.editable)">
                        <b-form-input class="text-right"
                                      :value="$n(data.item.plan3)"
                                      @change="v => data.item.plan3 = v"
                                      @keyup.enter.exact="keyup13"
                                      @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                      @blur="inputFixed(data.item, 'plan3', data.item.plan3, 1)">
                        </b-form-input>
                    </template>
                    <template v-else>
                        <div class="text-right">
                            {{ (isNaN(data.value) || (data.value === 0)) ? null : $n(data.value) }}
                        </div>
                    </template>
                </template>
                <template #bottom-row="data">
                    <td></td>
                    <td class="text-left" colspan="4">ИТОГО</td>
                    <td class="text-right">{{ $n(total('receipt_prev')) }}</td>
                    <td class="text-right">{{ $n(total('plan')) }}</td>
                    <td class="text-right">{{ $n(total('receipt_curr')) }}</td>
                    <td></td>
                    <td class="text-right">{{ $n(total('budget_request')) }}</td>
                    <td class="text-right">{{ $n(total('plan1')) }}</td>
                    <td class="text-right">{{ $n(total('deviation')) }}</td>
                    <td class="text-right">{{ $n(total('plan2')) }}</td>
                    <td class="text-right">{{ $n(total('plan3')) }}</td>
                    <td colspan="2"></td>
                </template>
            </b-table>
            <div class="table-footer">
                <div class="table-buttons">
                    <!-- <b-button variant="default">Сбросить</b-button>
                    <b-button variant="default">Отменить</b-button> -->
                    <b-button variant="success" @click="saveDatas">Сохранить</b-button>
                </div>
                <div class="table-pagination">
                    <b-form-group label="Строк на стр.:">
                        <!--<b-form-select v-model="selected" :options="options"></b-form-select>-->
                    </b-form-group>
                    <div class="pagination">
                        <span class="prev"><i class="icon icon-keyboard icon-rotate-180"></i></span>
                        <span>1</span>
                        <span class="active">2</span>
                        <input type="text"/>
                        <span>26</span>
                        <span>27</span>
                        <span class="next"><i class="icon icon-keyboard"></i></span>
                    </div>
                </div>
            </div>
        </b-card>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import store from '@/services/store';

export default {
    name: 'CostForm',
    props: {
        year: Number,

        obl: String,
        reg: String,
        variant: String,

        listReg: Array
    },
    data() {
        return {
            tableFields: [
                {
                    key: 'action',
                    label: ' '
                },
                {
                    key: 'abp',
                    label: 'АБП'
                },
                {
                    key: 'prg',
                    label: 'БП'
                },
                {
                    key: 'ppr',
                    label: 'БПП'
                },
                {
                    key: 'name_ru',
                    label: 'Наименование'
                },
                {
                    key: 'receipt_prev',
                    label: ''
                },
                {
                    key: 'plan',
                    label: 'План на текущий год, тыс.тенге'
                },
                {
                    key: 'receipt_curr',
                    label: 'Кассовые расходы текущего года, тыс.тенге'
                },
                {
                    key: 'percent',
                    label: '% исполнения',
                    variant: 'info'
                },
                {
                    key: 'budget_request',
                    label: 'Бюджетная заявка АБП, тыс.тенге'
                },
                {
                    key: 'plan1',
                    label: ''
                },
                {
                    key: 'deviation',
                    label: 'Отклонение, тыс.тенге',
                    variant: 'info'
                },
                {
                    key: 'plan2',
                    label: ''
                },
                {
                    key: 'plan3',
                    label: ''
                },
                {
                    key: 'filter',
                    label: 'filter',
                    thClass: 'd-none',
                    tdClass: 'd-none'
                }
            ],
            calcFlds: [
                'receipt_prev',
                'plan',
                'receipt_curr',
                'budget_request',
                'plan1',
                'plan2',
                'plan3'
            ],

            dictTree: null,
            ebkFunc: { dict: [], maps: {} },
            dictCost: [],
            bdata: {
                abp: new Map(),
                pprMap: new Map()
            },

            selectedTree: [],
            budgetForm: [],
            budget: [],
            pprMap: new Map(),
            valMap: new Map(),

            filter: {
                abp: null,
                prg: null,
                ppr: null,
                name_ru: null,
                search: null,
                on: ['filter', 'abp', 'prg', 'ppr', 'name_ru']
            },
            open: true,
            editing: false,
            bar: 0,
            isBusy: false,
            region: null
        };
    },

    created() {
        this.$watch('filter.abp', this.filterUpdate);
        this.$watch('filter.prg', this.filterUpdate);
        this.$watch('filter.ppr', this.filterUpdate);
        this.$watch('filter.name_ru', this.filterUpdate);
        this.$watch('reg', this.region = this.reg);
    },

    async mounted() {
        this.tableFields[5].label = 'Кассовые расходы за ' + (this.year - 1) + ' год, тыс тенге';
        this.tableFields[10].label = 'Прогноз на ' + (this.year + 1) + ' год, тыс тенге';
        this.tableFields[12].label = 'Прогноз на ' + (this.year + 2) + ' год, тыс тенге';
        this.tableFields[13].label = 'Прогноз на ' + (this.year + 3) + ' год, тыс тенге';

        await this.loadDictTree();
        this.bar = 70;
        await this.prepareForm();
        this.bar = 100;
        await this.rememberDatas();
    },

    beforeUpdate() {
        this.budgetForm.forEach(row => {
            if ([0, 3].includes(row.type) || ((row.type === 4) && row.hasChild)) {
                row._rowVariant = 'info';
            }
            if ((row.type === 5) || ((row.type === 4) && !row.hasChild)) {
                this.$set(row, 'editable', true);
            }
        });
    },

    methods: {
        async addAbp() {
            const that = this;

            that.budgetForm.splice(0);
            await that.prepareForm();
            await that.rememberDatas();
        },

        clearDatas() {
            const that = this;

            for (const ppr of that.pprMap.values()) {
                that.calcFlds.forEach((field) => {
                    that.$set(ppr, field, 0);
                });
            }
        }, // очищает данные таблицы

        createTable(elem, parentId) { // создание таблицы на основе дерева
            const that = this;

            const item = Object.assign({}, elem);

            that.$set(item, 'parent_id', parentId);
            that.$set(item, 'visible', true);
            Object.defineProperty(item, 'parent', {
                get: function () {
                    for (const row of that.budgetForm) {
                        if (item.parent_id === row.id) {
                            return row;
                        }
                    }
                    return null;
                }
            });
            Object.defineProperty(item, 'percent', {
                get: function () {
                    const rate = parseFloat(item.receipt_curr) / parseFloat(item.plan) * 100;
                    return (isNaN(rate) || !isFinite(rate) ? 0 : parseFloat(rate.toFixed(2)));
                }
            });
            Object.defineProperty(item, 'deviation', {
                get: function () {
                    return parseFloat((item.budget_request - item.plan1).toFixed(2));
                }
            });

            if (item.type > 4 || (item.type === 4 && !item.pprChild)) {
                that.calcFlds.forEach((field) => {
                    if (item.hasOwnProperty(field)) {
                        that.$set(item, field, parseFloat(item[field]));
                    } else {
                        that.$set(item, field, 0);
                    }
                });
                that.pprMap.set(that.getRowKey(item, (item.ppr !== null ? ['abp', 'prg', 'ppr'] : ['abp', 'prg'])), item);
                // that.pprMap.set(that.getRowKey(item, ['abp', 'pgr', 'ppr']), item);
            } else {
                that.$set(item, 'open', true);
                that.$set(item, 'hasChild', true);

                that.calcFlds.forEach(field => {
                    Object.defineProperty(item, field, {
                        get: function () {
                            return that.reSum(item, field);
                        }
                    });
                });
            }
            that.$set(item, 'index', that.budgetForm.length);
            that.$set(that.budgetForm, that.budgetForm.length, item);

            if (item.hasChild) {
                for (const ch of item.child) {
                    that.createTable(ch.value, item.id);
                }
                delete item.child;
            }
        }, // древовидную выборку преобразовывает в таблицу (для отображения)

        compareDatas() { // выясняем есть ли введенные значения для сохранения
            const that = this;

            const user = store.state.user.login;

            const saveDatas = [];
            for (const row of that.pprMap.values()) {
                const val = that.valMap.get(that.getRowKey(row, row.ppr === null ? ['abp', 'prg'] : ['abp', 'prg', 'ppr']));

                if (JSON.stringify(row) !== val) {
                    saveDatas.push(that.newCost(row.gr, row.pgr, row.abp, row.prg, row.ppr,
                        parseFloat(row.budget_request), null,
                        that.year, that.region, that.variant, user));
                    saveDatas.push(that.newCost(row.gr, row.pgr, row.abp, row.prg, row.ppr, null,
                        parseFloat(row.plan1), that.year + 1, that.region, that.variant, user));
                    saveDatas.push(that.newCost(row.gr, row.pgr, row.abp, row.prg, row.ppr, null,
                        parseFloat(row.plan2), that.year + 2, that.region, that.variant, user));
                    saveDatas.push(that.newCost(row.gr, row.pgr, row.abp, row.prg, row.ppr, null,
                        parseFloat(row.plan3), that.year + 3, that.region, that.variant, user));
                }
            }
            return saveDatas;
        }, // сравнивает введенные данные с ранее сохраненные и формирует массив новых записей для сохранения в БД

        filterUpdate() {
            this.filter.search = this.isStr(this.filter.abp)
                + this.isStr(this.filter.prg)
                + this.isStr(this.filter.ppr)
                + this.isStr(this.filter.name_ru);
            if (this.filter.search.length === 0) {
                this.filter.search = null;
            }
        }, // формирует строку поиска

        getRowKey(row, keys) {
            let key = '';
            for (const k of keys) {
                key = key + this.padLeadingZeros(row[k], 3) + '.';
            }
            return key;
        }, // преобразует значения выбранных полей в код

        inputFixed(item, field, value, digit) {
            const that = this;
            that.$set(item, field, parseFloat(parseFloat(value).toFixed(digit)));
            that.editing = true;
        }, // форматирует введенное значение до digit цифр после запятой

        isStr(value) {
            return ((value === null) || (value === 'None') ? '' : value);
        },

        keyup13: function (event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            // focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        }, // enter работает как tab

        async loadDictTree() {
            const that = this;

            try {
                const response = await fetch('/api-py/cost-form/' + that.year + '/' + that.region + '/' + that.variant);
                const items = await response.json();

                that.dictTree = items.tree;
                // that.pprMap = new Map(Object.entries(items.pprMap));
                for (const abp of that.dictTree) {
                    if (that.selectedTree.length < 3) {
                        that.selectedTree.push(abp);
                    }
                }
            } catch (error) {
                that.makeToast('danger', 'Ошибка запроса loadDictTree', error.toString());
            }
        },

        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение

        newCost(gr, pgr, abp, prg, ppr, budget_request, value, year, region, variant, user_name) {
            return {
                gr: gr,
                pgr: pgr,
                abp: abp,
                prg: prg,
                ppr: ppr,
                budget_request: budget_request,
                value: value,
                year: year,
                region: region,
                variant: variant,
                user_name: user_name
            };
        }, // формирует элемент для сохранение в БД

        keyPress: function (event, pattern) {
            // const regex = new RegExp('^[0-9]+$');
            // const regex = new RegExp('^-?\\d*\\d{0,9}$');
            // const regex = new RegExp('^-?\\d*\\.?\\d{0,9}$');
            const regex = new RegExp(pattern);
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }, // вводит по заданному паттерну

        openAll() {
            this.open = !this.open;

            for (const row of this.budgetForm) {
                row.visible = this.open;
                if ([3, 4].includes(row.type)) {
                    row.open = this.open;
                }
                if ([0, 3].includes(row.type)) {
                    row.visible = true;
                }
            }
        }, // открывает.закрывает все ветки

        openChilds(parent, bool) {
            parent.open = (bool === undefined ? !parent.open : bool);

            for (const row of this.budgetForm) {
                if (parent.id === row.parent_id) {
                    if ([3, 4].includes(row.type)) {
                        this.openChilds(row, parent.open);
                    }
                    row.visible = parent.open;
                }
            }
        }, // открывает/закрывает ветку до конечного элемента

        padLeadingZeros(num, size) {
            let s = String(num);
            while (s.length < size) { s = '0' + s; }
            return s;
        }, // добавляет 0-ли перед num до size-значного размера

        async prepareForm() {
            const that = this;

            for (const item of that.selectedTree) {
                await that.createTable(item.value, 0);
            }
            that.openAll();
        }, // подготовка отображения данных

        async rememberDatas() { // подтягиваем сохраненные данные
            const that = this;

            that.valMap.clear();
            for (const ppr of that.pprMap.values()) {
                that.valMap.set(that.getRowKey(ppr, ppr.ppr === null ? ['abp', 'prg'] : ['abp', 'prg', 'ppr']), JSON.stringify(ppr));
            }
        }, // расставляет сохранненные данные по полям

        reSum(parent, field) {
            let sum = 0;

            this.budgetForm.forEach(row => {
                if ((row.parent_id === parent.id) && (row.type !== parent.type)) {
                    sum += parseFloat(row[field]);
                }
            });
            return parseFloat(sum.toFixed(2));
        }, // пересчет суммы

        rowClass(item, type) {
            if (!item || type !== 'row') {
                return;
            }
            if (!item.visible) {
                return 'is-hidden';
            }
        }, // задает класс 'is-hidden' заданной строке

        saveDatas() {
            const res = this.compareDatas();
            if (res.length > 0) {
                this.$bvModal.msgBoxConfirm(
                    'Вы действительно хотите сохранить данные?',
                    {
                        title: 'Подтверждение',
                        size: 'lg',
                        buttonSize: 'sm',
                        okVariant: 'success',
                        okTitle: 'ДА',
                        cancelTitle: 'Отмена',
                        footerClass: 'p-2',
                        hideHeaderClose: false,
                        centered: true
                    })
                    .then(value => {
                        if (value) {
                            this.saveVariant(res);
                            this.editing = false;
                        } else {
                            this.editing = false;
                        }
                    })
                    .catch(error => {
                        this.makeToast('danger', 'Ошибка сохранения', error.toString());
                    });
            }
        }, // вызывает сохранение записей

        async saveVariant(values) {
            try {
                const response = await fetch('/api-py/save-cost-budget', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(values)
                });
                const result = await response.json();

                if ((response.status === 200) && (result.result === 'success')) {
                    await this.rememberDatas();
                    this.makeToast('success', 'Сообщение', 'Данные сохранены');
                }
            } catch {
                this.makeToast('danger', 'Предупреждение', 'Ошибка сохранения данных');
            }
        }, // сохранение записей в БД

        total(field) {
            let sum = 0;
            for (const row of this.budgetForm) {
                if (row.type === 3) {
                    sum += parseFloat(row[field]);
                }
            }
            return parseFloat(sum.toFixed(2));
        }, // итого по заданному полю

        async updateDatas() { // подтягиваем сохраненные данные
            const that = this;

            await that.clearDatas();
            that.bar = 30;
            try {
                const response = await fetch('/api-py/cost-form/' + that.year + '/' + that.region + '/' + that.variant);
                const items = await response.json();

                const values = new Map(Object.entries(items.pprMap));

                for (const row of values.values()) {
                    const ppr = that.pprMap.get(that.getRowKey(row, row.ppr === null ? ['abp', 'prg'] : ['abp', 'prg', 'ppr']));
                    if (ppr !== undefined) {
                        ppr.receipt_prev = row.receipt_prev;
                        ppr.receipt_curr = row.receipt_curr;
                        ppr.budget_request = row.budget_request;
                        ppr.plan = row.plan;
                        ppr.plan1 = row.plan1;
                        ppr.plan2 = row.plan2;
                        ppr.plan3 = row.plan3;
                    }
                }
            } catch (error) {
                that.makeToast('danger', 'Ошибка запроса updateDatas', error.toString());
            }
            that.bar = 100;
            await that.rememberDatas();
        } // расставляет сохранненные данные по полям
    }
};
</script>